export default {
    state: {
        // hl_urldentisalud: "http://backdentisalud.helexiumfiles.com/api/", //ok
        hl_urldentisalud: "https://localhost:44388/api/",
    },
    mutations: {

    },
    actions: {
        async denti_get(context, { path }) {
            try {
                console.log('get dentisalud--->')
                let token = localStorage.getItem("tk");
                const options = {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                };
                let res = await fetch(context.state.hl_urldentisalud + path, options);
                // console.log("get", res);
                let req = await res.json(); // await JSON.parse(res)
                if (res.mensaje == "Token inválida") {
                    //router.push('/')
                }
                if (res.error) {
                    console.error(res.error);
                    alert(res.mensaje);
                } else {
                    return req;
                }
            } catch (error) {
                console.error("error", error);
                return error;
            }
        },
    },
};

